@import '../../styles/customMediaQueries.css';

.root {}

/* heroContainer gives the height for SectionHero */
/* Safari has a bug with vw padding inside flexbox. Therefore we need an extra div (container) */
/* If you've lot of content for the hero, multiple lines of text, make sure to adjust min-heights for each media breakpoint accordingly */
.heroContainer {
  display: flex;
  flex-direction: column;
  min-height: 300px;
  height: 67.5vh;
  max-height: 400px;
  padding: 0;

  @media (--viewportMedium) {
    min-height: 500px;
    height: 70vh;
    max-height: 500px;
  }

  @media (--viewportLarge) {
    max-height: 500px;
    min-height: 400px;
    height: calc(70vh - var(--topbarHeightDesktop));

  }
}

.hero {
  flex-grow: 1;
  justify-content: center;
  padding-bottom: 0px;

  @media (--viewportMedium) {
    padding-bottom: 0px;
  }

  @media (--viewportLarge) {
    justify-content: center;
    padding-top: 0px;
  }
}

.sections {
  margin: 0;
  padding-top: 1px;
}

.section {
  overflow: auto;
}

/* Square corners for the last section if it's even */
.section:nth-of-type(2n):last-of-type {
  @media (--viewportMedium) {
    border-radius: 4px 4px 0 0;
  }
}

/* Every other section has a light background */
.section:nth-of-type(2n) {
  background-color: var(--matterColorLight);

  @media (--viewportMedium) {
    border-radius: 4px;
  }
}

.sectionContent {
  margin: var(--LandingPage_sectionMarginTop) 24px 51px 24px;

  @media (--viewportMedium) {
    max-width: 100%;
    margin: var(--LandingPage_sectionMarginTopMedium) 24px 60px 24px;
  }

  @media (--viewportLarge) {
    max-width: 1128px;
    padding: 0 36px 0 36px;
    margin: var(--LandingPage_sectionMarginTopLarge) auto 93px auto;
  }

  @media (--viewportXLarge) {
    max-width: 1056px;
    padding: 0;
  }
}

.sectionContentFirstChild {
  composes: sectionContent;
  margin-top: 3vh;
}

/* A bar on top of light sections */
.section:nth-of-type(2n) .sectionContent::before {
  background: var(--marketplaceColor);
  content: '';
  display: block;
  width: 109px;
  height: 6px;

  /* Place the bar on top of .sectionContent top margin */
  position: relative;
  top: calc(-1 * var(--LandingPage_sectionMarginTop));

  @media (--viewportMedium) {
    width: 192px;
    height: 8px;
    top: calc(-1 * var(--LandingPage_sectionMarginTopMedium));
  }

  @media (--viewportLarge) {
    top: calc(-1 * var(--LandingPage_sectionMarginTopLarge));
  }
}


/***********************************--- MY CSS ---*************************************/

.container {
  max-width: 100%;
  width: 100%;
  margin: 0 auto;
  margin-bottom: 50px;
  padding: 10px;
  max-width: 1080px;

  @media (--viewportMedium) {
    padding: 0px;
  }
}

.container h1 {
  margin-top: 20px;
  margin-bottom: 20px;

  @media (--viewportMedium) {
    margin-top: 40px;
    margin-bottom: 40px;
  }
}

.categoryContainer {
  text-align: center;
}

.categoryImg1,
.categoryImg2,
.categoryImg3,
.categoryImg4 {
  background-position: center;
  background-size: cover;
  border-radius: 6px;
  height: 270px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  transition: var(--transitionStyleButton);

  &:hover {
    transform: scale(1.02);
    box-shadow: var(--boxShadowSectionLocationHover);
  }
}

.categoryImg1 h3,
.categoryImg2 h3,
.categoryImg3 h3,
.categoryImg4 h3 {
  font-size: 22px;
}

.categoryImg1 {
  background-image: url(../../assets/home/photos/rent.jpg);
}

.categoryImg2 {
  background-image: url(../../assets/home/photos/share.jpg);
}

.categoryImg3 {
  background-image: url(../../assets/home/photos/share2.jpg);
}

.categoryImg4 {
  background-image: url(../../assets/home/photos/jo2024-2.jpg);
}

.img {
  max-width: 100%;
  max-height: 100%;
  border-radius: 6px;
}

.blockContainer {
  text-align: center;
}

.blockImgContainer {
  position: relative;
  height: 300px;
  width: 100%;
  max-width: 100%;
  max-height: 100%;
  margin: 0 auto;

  @media (--viewportMedium) {
    text-align: left;
    width: 400px;

  }
}

.blockTextContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.blockTextContainer h2 {
  color: var(--matterColorDark);
  text-align: center;
  /* margin: 0 auto;
  width: 70%; */
  margin-bottom: 20px;

  @media (--viewportMedium) {
    text-align: left;

  }

}

.blockTextContainer p {
  font-size: 16px;
  line-height: 20px;
  /* width: 70%;
  margin: 0 auto; */
  text-align: left;
}

.blockContainer img {
  min-width: 100%;
  height: 300px;
  border-radius: 6px;
  object-fit: cover;

  /* object-position: 100% 0; */
  @media (--viewportMedium) {
    min-width: 400px;
    height: 300px;
  }
}

.pictoContainer {
  text-align: center;
  width: 80%;
  margin: 0 auto;

  @media (--viewportMedium) {
    width: 100%;
  }
}

.picto1 img,
.picto2 img,
.picto3 img,
.picto4 img {
  height: 40px;

  @media (--viewportMedium) {
    height: 60px;
  }
}

.picto1 h3,
.picto2 h3,
.picto3 h3,
.picto4 h3 {
  font-weight: var(--fontWeightMedium);
  font-size: 19px;
}

.picto1,
.picto2,
.picto3,
.picto4 {
  width: 100%;

  @media (--viewportMedium) {
    width: 70%;
    margin: 0 auto;
  }

}

.paper {}

.ListingCardAuthor {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 5px;
  padding-right: 5px;
}

.ListingCardAuthor p {
  color: var(--marketplaceColor);
  font-weight: var(--fontWeightSemiBold);
  font-size: 16px;
  margin-left: 10px;
}

.listingsContainer {
  text-align: center;
}

.sponsorContainer {
  text-align: center;
}

.videoContainer {}

.blockImgContainer svg {
  display: none;

  @media (--viewportMedium) {
    display: block;
  }
}

.showMobile {
  display: block;

  @media (--viewportMedium) {
    display: none;
  }
}

.hideMobile {
  display: none;

  @media (--viewportMedium) {
    display: block;
  }
}

.block {
  @media (--viewportMedium) {
    display: flex;
    justify-content: space-around;
    width: 75%;
    margin: 0 auto;
  }
}

.block2 {
  display: none;

  @media (--viewportMedium) {
    display: flex;
    justify-content: space-around;
    width: 75%;
    margin: 0 auto;
  }
}

.block2Mobile {
  display: block;

  @media (--viewportMedium) {
    display: none;
  }
}

.blockImage {
  position: relative;
  /* height: 400px; */
  max-width: 500px;
  margin: 0 auto;


}

.blockText {
  text-align: left;
  margin-left: 30px;
  align-items: center;
  display: flex;
}

.stampRight {
  display: none;

  @media (--viewportMedium) {
    display: block;
    position: absolute;
    height: 100px;
    top: -20px;
    left: -60px;
    z-index: -1;
  }
}

.stampLeft {
  display: none;

  @media (--viewportMedium) {
    display: block;
    position: absolute;
    height: 100px;
    top: -20px;
    right: -60px;
    z-index: -1;
  }
}

.listingCard {
  margin-bottom: 20px;

  @media (--viewportMedium) {
    /* margin-left: 20px; */
  }
}

.blockTitle {
  line-height: 25px;
  margin-bottom: 0px;
}



.clientContainer {
  text-align: center;
}

.slideShowWrapper {
  display: flex;
  justify-content: space-around;

}

.slideShowContent {
  width: 300px;


}

.slideText {
  width: calc(100% - 3rem);
  /*max-width: 400px; */
  background-color: var(--matterColorFilter) !important;
  margin: 5px;
  border-radius: 6px;
  height: 370px;

  @media (--viewportLarge) {
    height: 200px;
  }

  @media (--viewportMedium) {
    height: 200px;
  }
}

.eachslide>div {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  background-size: cover;
  height: 350px;

  @media (--viewportLarge) {
    height: 200px;
  }

  @media (--viewportMedium) {
    height: 200px;
  }
}

.eachslide p {
  padding: 18px;

  @media (--viewportLarge) {
    padding: 25px;
  }

  @media (--viewportMedium) {
    padding: 25px;
  }

  font-size: 14px;
  line-height: 20px;
  background: #fff;
  text-align: center;
  /* height: max-content;*/
}

.clientContainer svg {
  color: var(--matterColorDark);
}


.mediaContainer {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 45px;
  margin-bottom: 48px;
}

.mediaContainer a {
  margin: 10px;
}